import React from "react";
import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Home = ()=>{

    const navigate = useNavigate();

    const route_to_Lazem = ()=>{
        window.location.href = "https://lazem.sa";
    }

    route_to_Lazem();

    return(
        <div></div>
    )
}

export default Home