import React, { useState } from "react";
import './Certificate-View.css';
import downloadjs from "downloadjs";
import QRCode from "react-qr-code";
import JsPDF from 'jspdf';
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';



const Certificate_View = ()=>{
    const [htmldata, setHTMLData] = useState(null);
    const [cssdata, setCSSData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [full_name, setFullName] = useState("");
    const [instructor_name, setInstructorName] = useState("");
    const [instructor_id, setInstructorId] = useState(null);
    const [date_yy, setDateYY] = useState("");
    const [date_dd, setDateDD] = useState("");
    const [date_mm, setDateMM] = useState("");
    const [course_name, setCourseName] = useState(null);
    const [designVersion, setDesignVersion] = useState("");
    const [expire_date, setExpireDate] = useState("");
    const { id } = useParams()

      const qr_value = ()=>{
        const val = "https://certificate.lazem.sa/certificate/" + id
        return val
      }

      async function GetCertificateData() {
        const actualData: any = await fetch(
          "https://test.lazem.sa/v1/training/certificate/" + id
        )
          .then(async (response) => {
            let _data = await response.json();

            
            getDesignData(_data["session"].certificate_design);
            // console.log(_data["session"].certificate_design);
    
            setInstructorName(
              _data["session"].instructor["user"].first_name +
                " " +
                _data["session"].instructor["user"].middle_name +
                " " +
                _data["session"].instructor["user"].last_name
            );
            setFullName(
              _data["user"].first_name +
                " " +
                _data["user"].middle_name +
                " " +
                _data["user"].last_name
            );
            setInstructorId(_data["session"].instructor["id"]);
            setCourseName(_data["session"].title);
            let date = new Date(_data["create_date"]);
            let date_2 = new Date(date)
            date_2 = new Date(date_2.setFullYear(date_2.getFullYear() + 2));
            setExpireDate(date_2.getFullYear().toString().slice(2, 4));
            setDateDD(date.toString().slice(8, 10));
    
            setDateMM(date.toString().slice(4, 7));
          
            setDateYY(date.getFullYear().toString().slice(2, 4));
           

          })
          .catch((err) => {
            //setData(null);
            console.log(err); 
            setError(true);
          })
          .finally(() => {
            //setData('data');
            setLoading(false);
          });
      }

      GetCertificateData();


      async function getDesignData(id: string) {
        const actualData: any = await fetch(
          "https://test.lazem.sa/v1/training/certificate_design_detail/?certificate_design_id=" +
            id
        )
          .then(async (response) => {
            let _data = await response.json();
            // console.log(_data);
            let html_data = _data.find((t: any) => t.html_data);
            let css_data = _data.find((t: any) => t.css_data);

            console.log("Certificate Version:")
            // version 1 is the old one
            setDesignVersion(_data[0].version)

            console.log(designVersion)
            // _data.forEach((element: any) => {
            //   ////////console.log(element)
            // });
            setCSSData(css_data["css_data"]);
            // console.log(css_data["css_data"])

            setHTMLData(html_data["html_data"]);
          })
          .catch((err) => {
            setHTMLData(null);
            ////console.log(err);
            setError(true);
          })
          .finally(() => {
            //setData('data');
            setLoading(false);
          });
        //////////console.log(actualData) ;
        //return actualData;
      }

    const print = async () => {
        const canva = await html2canvas(
          document.getElementById("print") || document.body,
          {useCORS: true, allowTaint:true }

        ).then((canves=>{

            const dataURL = canves.toDataURL("image/png");
            downloadjs(dataURL, full_name + " certificate", "image/png");
        }));
      };

      function unPackHtml(_data: any) {
        //////console.log(full_name)
        let html = _data;
        html = html
          .replace("{{full_name}}", full_name)
          .replace("{{instructor_name}}", instructor_name)
          .replace("{{course_name}}", course_name)
          .replace("{{dd}}", date_dd)
          .replace("{{dd}}", date_dd)
          .replace("{{mm}}", date_mm)
          .replace("{{mm}}", date_mm)
          .replace("{{yy}}", date_yy)
          .replace("{{instructor_number}}", instructor_id)
          .replace("{{yy_ex_2}}", expire_date);
        return html;
      }
      
      function getHTMLData() {
        if (htmldata != null) {
          let _data = unPackHtml(htmldata);
          return _data;
        } else {
          return "تحميل...";
        }
      }

      function getCSSData() {
        if (cssdata != null || cssdata != undefined) {
          return cssdata;
        }
        return " ";
      }

      const certificate = ()=>{
        return(
          <>
          
            {loading?(
    
              <p>...تحميل</p>
              ):(
                <>
                  <div>
                    <p className="btn" onClick={() => print()}>
                    تحميل الشهادة
                    </p>
                  </div>
          
                  <div id="print">
                    <style dangerouslySetInnerHTML={{ __html: getCSSData() }}></style>

                    <div dangerouslySetInnerHTML={{ __html: getHTMLData() }}></div>
                    <div className="qrcode">
                      <QRCode value={qr_value()} fgColor={"#2c4c5b"}/>
                    </div>
                  </div>
              </>
              )
            }
          </>
        )
      }

      const error_html = ()=>{
        return(
          <div className="error-wrapper">
    
            <div className="error-img">
              <i className="fa-solid fa-circle-exclamation"></i>
            </div>

            
            <p className="error-msg">حدث خطأ ما، الرجاء التواصل مع الدعم الفني</p>
            <a href="mailto:support@lazem.sa" className="error-contact">
              support@lazem.sa
            </a>
          </div>
        )
      }

      
    return(
        <div className="wrapper">

          {error?(
            error_html()
            ):(
              <>
                {certificate()}
              </>
            )
          }

        </div>
    )
}

export default Certificate_View;

