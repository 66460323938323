import React, { useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import './Design-View.css'


const Design_View = ()=>{

    const [htmldata, setHTMLData] = useState<any>();
    const [cssdata, setCSSData] = useState<any>();
    const { id } = useParams()
    

    async function getDesignData(id: any) {
      const actualData: any = await fetch(
        "https://test.lazem.sa/v1/training/certificate_design_detail/?certificate_design_id=" +
          id
      )
        .then(async (response) => {
          let _data = await response.json();
          // console.log(_data);
          let html_data = _data.find((t: any) => t.html_data);
          let css_data = _data.find((t: any) => t.css_data);

          console.log("Certificate Version:")
          setCSSData(css_data["css_data"]);
          // console.log(css_data["css_data"])

          setHTMLData(html_data["html_data"]);
        })
        .catch((err) => {
          setHTMLData(null);
        })
        .finally(() => {
        });
      //////////console.log(actualData) ;
      //return actualData;
    }

    getDesignData(id);

    function getCSSData() {
      if (cssdata != null || cssdata != undefined) {
        return cssdata;
      }
      return " ";
    }

    function getHTMLData() {
      if (htmldata != null) {
        return htmldata;
      } else {
        return "تحميل...";
      }
    }

    const qr_value = ()=>{
      const val = "https://certificate.lazem.sa/view/certificate/" + id
      return val
    }

    return(
        <>
                <div className="wrapper">
                    <div id="print">
                      <style dangerouslySetInnerHTML={{ __html: getCSSData() }}></style>

                      <div dangerouslySetInnerHTML={{ __html: getHTMLData() }}></div>
                      <div className="qrcode">
                        <QRCode value={qr_value()} fgColor={"#2c4c5b"}/>
                      </div>
                    </div>
                </div>
        </>
    )

}

export default Design_View