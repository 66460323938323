import React from 'react';
import logo from './logo.svg';
import { Route, Routes} from "react-router-dom";
import './App.css';
import { Certificate } from 'crypto';
import Certificate_View from './Pages/Certificate-View';
import Design_View from './Pages/Design-View';
import Home from './Pages/Home';

function App() {
  return (
    <Routes>
    <Route path="/" element={<Home />}></Route>
    <Route path="/certificate/:id" element={<Certificate_View />}></Route>
    <Route path="/view/certificate/:id" element={<Design_View />}></Route>
    <Route path="*" element={<Home />} />
  </Routes>
  );
}

export default App;
